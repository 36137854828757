import renderModal from "@/utils/render-modal";
import AddLink from "./src/AddLink";
import UpdateLink from "./src/UpdateLink";
import Link from "./src/Link";

export function addLink(props) {
  return renderModal(AddLink, props);
}
export function updateLink(props) {
  return renderModal(UpdateLink, props);
}

export default Link;
