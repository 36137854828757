<template>
  <div class="link">
    <iframe v-if="link" class="iframe" :src="link"></iframe>
    <div v-else class="empty">
      <a-empty />
    </div>
  </div>
</template>

<script>
import workspace from "@/views/datamodel/models/workspace";

export default {
  computed: {
    node() {
      return workspace.getNodeById(workspace.currentDatasheetId);
    },
    link() {
      return this.node && this.node.link;
    }
  },
  created() {
    if (this.node) {
      this.node.nodeStatus.loading = false;
    }
  }
};
</script>

<style lang="less" scoped>
@width: 100%;
@height: 100%;
.link {
  overflow: hidden;
  width: @width;
  height: @height;
  .iframe {
    width: @width;
    height: @height;
    border: none;
  }
  .empty {
    width: @width;
    height: @height;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
