import { User } from "@/model";
import UnitType from "@/constant/unit-types";
import Team from "./team";

export default class Member extends User {
  /**
   * @type {Team[]}
   */
  teams = [];
  checked = false;
  unitType = UnitType.MEMBER;
  unitId = this.memberId;
  /**
   * @param {object} opts constructor options
   */
  constructor(opts = {}) {
    super(opts);
    Object.assign(this, opts);
    this.unitId = this.memberId;
  }
  setTeams(teams) {
    this.teams = Array.isArray(teams) ? teams.map(Team.from) : [];
  }
  /**
   * @returns {Member}
   */
  static from(opts) {
    return opts instanceof Member ? opts : new Member(opts);
  }
}
