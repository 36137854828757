<template>
  <a-modal
    width="560px"
    :title="`设置权限 @ ${nodeName}`"
    :footer="null"
    :body-style="{ paddingTop: 0 }"
  >
    <div class="toolbar">
      <a-button size="small" type="primary" @click="onAdd()">添加成员</a-button>
      <a-button size="small" type="danger" @click="onRemoveChecked()">
        批量移除
      </a-button>
      <span style="flex: 1 1 0"></span>
      <a-input-search v-model="search" placeholder="搜索" />
    </div>
    <div class="main">
      <a-skeleton v-if="loading" active />
      <a-empty
        v-else-if="!units.length"
        description="暂无数据"
        class="ndl-margin-top-xl"
      />
      <Unit
        v-for="(item, index) in units"
        :key="index"
        :unit="item"
        @change="onRoleChange(item)"
        @remove="onRoleRemove(item)"
      />
    </div>
  </a-modal>
</template>

<script>
import * as API from "@/api/node";
import { pickUnits } from "./index";
import Unit from "./ListItem";

export default {
  inheritAttrs: false,
  components: { Unit },
  props: {
    nodeId: String,
    nodeName: String
  },
  data() {
    return {
      loading: true,
      search: "",
      permissions: {}
    };
  },
  computed: {
    units() {
      const search = this.search.toLowerCase().trim();
      const units = this.permissions.roleUnits || [];
      if (!search) {
        return units;
      }
      return units.filter(item => item.unitName.toLowerCase().includes(search));
    }
  },
  created() {
    this.getPermissions();
  },
  methods: {
    async getPermissions() {
      const { data } = await API.getNodeRoleList(this.nodeId);
      const members = data.members || [];
      for (const item of data.roleUnits) {
        const member = members.find(member => member.memberId === item.unitId);
        // 前端展示时 roleType 以 roleUnits 中展示的为准
        // members 中的 roleType 是该成员最终拥有的实际权限
        // 因为目前的机制是以「成员」、「成员所属团队」中的最高权限去授权的
        if (member) {
          const roleType = item.roleType;
          Object.assign(item, member);
          item.roleType = roleType;
        }
        item.checked = false;
      }
      data.roleUnits.sort((a, b) => a.unitType - b.unitType);
      this.permissions = data;
      this.loading = false;
    },
    onAdd() {
      pickUnits({
        title: "添加团队或成员",
        showTeam: true,
        beforeConfirm: async (units, roleType) => {
          const nodeId = this.nodeId;
          const roles = units.map(unit => {
            const { unitId, unitType } = unit;
            return { nodeId, roleType, unitId, unitType };
          });
          await API.addNodeRole(roles);
          return this.getPermissions();
        }
      });
    },
    onRemoveChecked() {
      const units = this.units;
      this.permissions.roleUnits = units.filter(item => !item.checked);
      const nodeId = this.nodeId;
      const data = units
        .filter(item => item.checked)
        .map(item => ({ nodeId, unitId: item.unitId }));
      if (data.length) {
        API.deleteNodeRole(data);
      }
    },
    onRoleChange(item) {
      API.updateNodeRole({ ...item, nodeId: this.nodeId });
    },
    onRoleRemove(item) {
      this.permissions.roleUnits = this.units.filter(unit => unit !== item);
      API.deleteNodeRole([{ nodeId: this.nodeId, unitId: item.unitId }]);
    }
  }
};
</script>

<style lang="less" scoped>
.toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  .ant-btn {
    border-radius: 2px;
    margin-right: 12px;
  }
  .ant-input-search {
    width: 220px;
  }
  /deep/ .ant-input {
    background: #f5f5f5;
    border-radius: 2px;
    box-shadow: none;
    border: none;
  }
}
.main {
  height: 500px;
  overflow: auto;
}
</style>
