<template>
  <div class="member" @click="onClick()">
    <Avatar
      circle
      size="small"
      :border="false"
      :color="member.memberId | colorOf"
      :src="member.avatar"
      :text="member.memberName"
    />
    <div class="meta">
      <div class="name">
        <span>{{ member.memberName }}</span>
        <Role :member="member" />
      </div>
      <span class="tel" :title="memberTitle">
        {{ member.mobile || member.mail || "-" }}
      </span>
    </div>
    <a-checkbox v-model="member.checked" @input="$emit('select', $event)" />
  </div>
</template>

<script>
import Avatar, { colorOf } from "@/components/avatar";
import Role from "@/views/settings/components/member-role";

export default {
  components: { Avatar, Role },
  props: {
    member: Object
  },
  computed: {
    memberTitle() {
      const { memberName, mobile, mail } = this.member || {};
      // eslint-disable-next-line prettier/prettier
      return `成员名称：${memberName}\n手机号：${mobile || "-"}\n邮箱：${mail || "-"}`;
    }
  },
  filters: { colorOf },
  methods: {
    onClick() {
      const value = !this.member.checked;
      this.member.checked = value;
      this.$emit("select", value);
    }
  }
};
</script>

<style lang="less" scoped>
.member {
  height: 60px;
  padding: 0 20px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  &:hover {
    background: #f5f5f5;
  }
  .avatar-wrapper {
    flex-shrink: 0;
  }
  .meta {
    flex: 1 1 0;
    overflow: hidden;
    margin-left: 14px;
  }
  .name {
    margin-bottom: 4px;
  }
  .name > span:first-child {
    margin-right: 8px;
  }
  .tel {
    color: rgba(0, 0, 0, 0.45);
    font-size: 13px;
  }
}
.ant-checkbox-wrapper {
  flex-shrink: 0;
  margin-left: 12px;
}
</style>
