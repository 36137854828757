import router from "@/router";
import { createNode } from "@/api/node";
import { ModelTree } from "@/views/datamodel/models/workspace";

export async function createDashboard(parent) {
  const parentId = parent && parent.nodeId;
  const nodeType = ModelTree.NODE_DASHBOARD;
  const nodeName = "未命名看板";
  const { data } = await createNode({ parentId, nodeType, nodeName });
  if (parent) {
    parent.addChild(data);
  }
  router.replace({
    name: "datamodel-workspace",
    params: {
      workspaceId: parentId,
      datasheetId: data.nodeId
    }
  });
}
