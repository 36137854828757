<template>
  <a-modal :closable="false" :width="width">
    <div class="title">{{ title }}</div>
    <a-input-search v-model="search" :placeholder="placeholder" />
    <!-- units -->
    <div class="units">
      <UnitTeam
        v-for="item in filteredTeams"
        :key="item.teamId"
        :team="item"
        @select="onSelect(item, $event)"
      />
      <UnitMember
        v-for="item in filteredMembers"
        :key="item.memberId"
        :member="item"
        @select="onSelect(item, $event)"
      />
    </div>
    <!-- footer buttons -->
    <a-button slot="footer" @click="$reject()">取消</a-button>
    <a-button
      slot="footer"
      type="primary"
      :disabled="!checkedUnits.length"
      @click="onConfirm()"
    >
      {{ okText }}
    </a-button>
    <span
      slot="footer"
      class="ndl-text-desc"
      style="float: left; margin-top: 6px"
    >
      已选择 {{ checkedUnits.length }} 项
    </span>
  </a-modal>
</template>

<script>
import clone from "lodash/cloneDeep";
import org from "@/views/settings/models/organization";
import UnitTeam from "./Team";
import UnitMember from "../../team-members/src/Member";

export default {
  components: { UnitTeam, UnitMember },
  props: {
    title: {
      type: String,
      default: "选择成员"
    },
    okText: {
      type: String,
      default: "确定"
    },
    width: {
      type: [String, Number],
      default: "420px"
    },
    showTeam: Boolean,
    showMember: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: "输入用户名或手机号搜索"
    },
    defaultChecked: {
      type: Function,
      default: () => false
    },
    $resolve: Function,
    $reject: Function
  },
  data() {
    return {
      org,
      teams: [],
      members: [],
      search: ""
    };
  },
  computed: {
    filteredTeams() {
      const search = this.search.toLowerCase().trim();
      if (!search) {
        return this.teams;
      }
      return this.teams.filter(item =>
        item.teamName.toLowerCase().includes(search)
      );
    },
    filteredMembers() {
      const search = this.search.toLowerCase().trim();
      if (!search) {
        return this.members;
      }
      return this.members.filter(item =>
        [item.memberName, item.mobile, item.mail]
          .join("")
          .toLowerCase()
          .includes(search)
      );
    },
    checkedUnits() {
      const checkedTeams = this.teams.filter(item => item.checked);
      const checkedMembers = this.members.filter(item => item.checked);
      return checkedTeams.concat(checkedMembers);
    }
  },
  created() {
    this.resetUnits();
  },
  watch: {
    "org.teams"() {
      this.resetUnits();
    },
    "org.members"() {
      this.resetUnits();
    }
  },
  methods: {
    resetUnits() {
      const isChecked = this.defaultChecked;
      this.teams = this.showTeam ? clone(org.teams) : [];
      for (const item of this.teams) {
        item.checked = isChecked(item);
      }
      this.members = this.showMember ? clone(org.members) : [];
      for (const item of this.members) {
        item.checked = isChecked(item);
      }
    },
    onSelect(item, checked) {
      if (this.multiple) {
        return;
      }
      for (const item of this.checkedUnits) {
        item.checked = false;
      }
      item.checked = checked;
    },
    onConfirm() {
      this.$resolve(this.checkedUnits);
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.ant-input-search {
  margin: 16px 0;
  /deep/ .ant-input {
    border-radius: 2px;
    box-shadow: none;
    background: #f5f5f5;
    border: none;
  }
}
.units {
  height: 380px;
  overflow: auto;
}
</style>
