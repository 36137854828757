<template>
  <a-modal title="修改链接" class="ndl-antd">
    <template slot="footer">
      <a-button key="back" @click="close">
        取消
      </a-button>
      <a-button
        key="submit"
        type="primary"
        @click="confirm"
        :loading="loading"
        :disabled="!link"
      >
        确定
      </a-button>
    </template>
    <a-input v-model="link" size="large" placeholder="请输入链接" />
  </a-modal>
</template>

<script>
import { updateNode } from "@/api/node";

export default {
  props: {
    $resolve: Function,
    $reject: Function,
    node: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      link: "",
      loading: false
    };
  },
  mounted() {
    this.link = this.node.link;
  },
  methods: {
    confirm() {
      this.loading = true;
      updateNode({
        nodeId: this.node.nodeId,
        link: this.link
      })
        .then(() => {
          this.node.link = this.link;
        })
        .catch(() => {
          this.$message.error("修改失败");
        })
        .finally(() => {
          this.loading = false;
          this.$resolve();
        });
    },
    close() {
      this.$reject();
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";
</style>
