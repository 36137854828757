<template>
  <a-tag v-if="member.isMainAdmin" color="blue">主管理员</a-tag>
  <a-tag v-else-if="member.isSubAdmin" color="orange">子管理员</a-tag>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    member: Object
  }
};
</script>

<style lang="less" scoped>
.ant-tag {
  border: none;
  border-radius: 2px;
  cursor: inherit;
}
</style>
