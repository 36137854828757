<template>
  <a-modal title="新建链接">
    <template slot="footer">
      <a-button key="back" @click="close">
        取消
      </a-button>
      <a-button
        key="submit"
        type="primary"
        @click="confirm"
        :loading="loading"
        :disabled="!name || !link"
      >
        确定
      </a-button>
    </template>
    <a-form class="ndl-antd" layout="vertical">
      <a-form-item label="链接名称" required>
        <a-input v-model="name" placeholder="请输入链接名称" />
      </a-form-item>
      <a-form-item label="链接" required>
        <a-input v-model="link" placeholder="请输入链接" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import router from "@/router";
import workspace, { ModelTree } from "@/views/datamodel/models/workspace";
import { createNode } from "@/api/node";

export default {
  props: {
    $resolve: Function,
    $reject: Function,
    node: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      name: "",
      link: "",
      loading: false
    };
  },
  methods: {
    confirm() {
      this.loading = true;
      const parentId = this.node.nodeId;
      createNode({
        parentId,
        nodeName: this.name,
        nodeType: ModelTree.NODE_LINK,
        link: this.link
      })
        .then(res => {
          const { data } = res;
          workspace.getNodeById(parentId).addChild(data);
          router.replace({
            name: "datamodel-workspace",
            params: {
              workspaceId: parentId,
              datasheetId: data.nodeId
            }
          });
        })
        .catch(() => {
          this.$message.error("新建失败");
        })
        .finally(() => {
          this.loading = false;
          this.$resolve();
        });
    },
    close() {
      this.$reject();
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";
</style>
