import renderModal from "@/utils/render-modal";
import List from "./List";
import Picker from "./Picker";

export function setPermissions(props) {
  return renderModal(List, props);
}

export function pickUnits(props) {
  return renderModal(Picker, props);
}

export default { setPermissions, pickUnits };
