import router from "@/router";
import workspace from "@/views/datamodel/models/workspace";
import { Modal } from "ant-design-vue";
import { ContextOption } from "@/components/contextmenu";
import * as Link from "@/views/datamodel/components/link-hybrid";
import * as Dashboard from "@/views/datamodel/components/dashboard/tools";
import { setPermissions } from "./permissions";

/**
 * @param {import("@/views/datamodel/models/workspace").Workspace} node
 */
export function gotoWorkspace(node) {
  const workspaceId = node.isFolder ? node.nodeId : node.parent.nodeId;
  const defaultSheet = node.isFolder ? node.children[0] : node;
  if (defaultSheet) {
    router.replace({
      name: "datamodel-workspace",
      params: {
        workspaceId,
        datasheetId: defaultSheet.nodeId
      }
    });
  } else {
    router.replace({
      name: "datamodel-workspace-guide",
      params: { workspaceId }
    });
  }
}

/**
 * 文件夹菜单
 * @param {import("@/views/datamodel/models/workspace").Workspace} node
 */
export function getFolderOptions(node) {
  const permissions = node.permissions;
  return [
    new ContextOption("rename", "重命名", "edit-line")
      // 只读 node 禁用
      .$disabled(permissions.readonly)
      .$listener(function() {
        node.nodeStatus.renaming = true;
      }),
    new ContextOption("permission", "设置权限", "lock", true)
      // 无管理权限的 node 禁用
      .$disabled(!permissions.manageable)
      .$listener(function() {
        setPermissions(node);
      }),
    new ContextOption("create-dashboard", "新建看板", "trello")
      .$disabled(!permissions.manageable)
      .$listener(function() {
        Dashboard.createDashboard(node);
      }),
    new ContextOption("create-link", "新建链接", "link", true)
      .$disabled(!permissions.manageable)
      .$listener(function() {
        Link.addLink({ node });
      }),
    // new ContextOption("share", "分享", "share-line", true)
    //   .$disabled(permissions.readonly)
    //   .$listener(function() {
    //     console.log("分享: ", node);
    //   }),
    new ContextOption("delete", "删除此项目", "trash-2")
      .$iconClass("ndl-color-red")
      .$labelClass("ndl-color-red")
      // 无管理权限的 node 禁用删除
      .$disabled(!permissions.manageable)
      .$listener(function() {
        Modal.confirm({
          title: "删除项目",
          content: `确定要删除「${node.nodeName}」吗？删除操作无法撤销。`,
          okType: "danger",
          onOk: async () => {
            await node.remove();
            if (node.nodeId !== router.currentRoute.params.workspaceId) {
              return;
            }
            const defaultWorkspace = workspace.children[0];
            if (!defaultWorkspace) {
              router.replace({ name: "datamodel-welcome" });
              return;
            }
            gotoWorkspace(defaultWorkspace);
          }
        });
      })
  ];
}

/**
 * 文件菜单
 * @param {import("@/views/datamodel/models/workspace").Workspace} node
 */
export function getFileOptions(node) {
  const permissions = node.permissions;
  return [
    new ContextOption("rename", "重命名", "edit-line")
      // 只读 node 禁用
      .$disabled(permissions.readonly)
      .$listener(function() {
        node.nodeStatus.renaming = true;
      }),
    node.isDatasheet
      ? new ContextOption("copy", "复制一份", "copy", true)
          .$disabled(!permissions.manageable)
          .$listener(async function() {
            const clonedNode = await node.clone();
            gotoWorkspace(clonedNode);
          })
      : null,
    new ContextOption("permission", "设置权限", "lock")
      // 无管理权限的 node 禁用
      .$disabled(!permissions.manageable)
      .$listener(function() {
        setPermissions(node);
      }),
    // new ContextOption("share", "分享", "share-line")
    //   .$disabled(permissions.readonly)
    //   .$listener(function() {
    //     console.log("分享: ", node);
    //   }),
    // new ContextOption("export-excel", "导出为 Excel", "excel-line")
    //   .$disabled(permissions.readonly)
    //   .$listener(function() {
    //     console.log("导出为 Excel 文件: ", node);
    //   }),
    node.isDatasheet
      ? new ContextOption("export-csv", "导出为 CSV", "file-text-line", true)
          .$disabled(permissions.readonly)
          .$listener(function() {
            node.exportCSV();
          })
      : null,
    node.isLink
      ? new ContextOption("update-link", "修改链接", "link", true)
          .$disabled(!permissions.manageable)
          .$listener(async function() {
            Link.updateLink({ node });
          })
      : null,
    new ContextOption("delete", "删除", "trash-2")
      .$iconClass("ndl-color-red")
      .$labelClass("ndl-color-red")
      // 无管理权限的 node 禁用删除
      .$disabled(!permissions.manageable)
      .$listener(function() {
        Modal.confirm({
          title: "删除提示",
          content: `确定要删除「${node.nodeName}」吗？删除操作无法撤销。`,
          okType: "danger",
          onOk: async () => {
            await node.remove();
            // 删除的是当前 sheet 时，自动切换到第一个 datasheet
            if (node.nodeId === router.currentRoute.params.datasheetId) {
              gotoWorkspace(workspace.currentWorkspace);
            }
          }
        });
      })
  ].filter(Boolean);
}

export default { getFolderOptions, getFileOptions };
