import Vue from "vue";
import * as API from "@/api/team";
import * as APISpace from "@/api/space";
import Team from "./team";
import Member from "./member";

export class Organization {
  // orgId 其实就是 team 树 的 root 节点的 teamId
  orgId = "";
  orgName = "";
  teams = [];
  members = [];
  /**
   * @type {Team}
   */
  focusedTeam = new Team({ isRoot: true });
  constructor() {
    this.update();
  }
  async update() {
    const { data: teamTree } = await API.treeOfTeam();
    const { data: memberList } = await API.listMember({
      teamId: teamTree.teamId
    });
    teamTree.isRoot = true;
    const teams = [teamTree].concat(teamTree.children || []).map(Team.from);
    const members = [].concat(memberList.data || []).map(Member.from);
    for (const teamItem of teams) {
      const teamMembers = members.filter(member =>
        member.teams.some(team => team.teamId === teamItem.teamId)
      );
      teamItem.setMembers(teamMembers);
    }
    for (const memberItem of members) {
      const memberTeams = teams.filter(team =>
        memberItem.teams.some(item => item.teamId === team.teamId)
      );
      memberItem.setTeams(memberTeams);
    }
    this.orgId = teamTree.teamId;
    this.orgName = teamTree.teamName;
    this.teams = teams;
    this.members = members;
    this.focusOnTeam(this.focusedTeam);
  }
  /**
   * @param {Team} team
   */
  async removeTeam(team) {
    await API.deleteTeam(team.teamId);
    return this.update();
  }
  async removeMembers(data) {
    // 当前选中的团队是根团队，则将成员踢出空间
    if (this.focusedTeam.isRoot) {
      await APISpace.deleteMemberFromSpace(data.memberId);
    } else {
      await API.deleteMember(data);
    }
    return this.update();
  }
  async addMembers(data) {
    await API.addMember(data);
    return this.update();
  }
  /**
   * @param {Team} team
   */
  async saveTeam(team) {
    if (team.teamId) {
      await API.updateTeam(team);
    } else {
      await API.creatTeam(team);
    }
    return this.update();
  }
  /**
   * @param {Team} team
   */
  focusOnTeam(team) {
    const teams = this.teams;
    this.focusedTeam = team
      ? teams.find(item => item.teamId === team.teamId) || teams[0]
      : teams[0];
  }
}

const org = new Organization();

export default Vue.observable(org);
