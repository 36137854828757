<template>
  <div class="unit">
    <a-checkbox v-model="unit.checked" />
    <Avatar v-bind="avatar" />
    <span class="label">{{ unit.unitName }}</span>
    <a-tag v-if="unit.isMainAdmin" color="blue">主管理员</a-tag>
    <a-tag v-else-if="unit.isSubAdmin" color="orange">子管理员</a-tag>
    <span class="placeholder"></span>
    <a-dropdown placement="bottomRight">
      <span class="rel-role">
        {{ unit.roleType | roleLabel }}
        <i class="ndl-icon-chevron-down"></i>
      </span>
      <a-menu slot="overlay" @click="onConfirm">
        <a-menu-item :key="RoleType.MANAGE">
          {{ RoleType.MANAGE | roleLabel }}
        </a-menu-item>
        <a-menu-item :key="RoleType.EDIT">
          {{ RoleType.EDIT | roleLabel }}
        </a-menu-item>
        <a-menu-item :key="RoleType.READ">
          {{ RoleType.READ | roleLabel }}
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <i class="rel-remove ndl-icon-trash-2" @click="$emit('remove')"></i>
  </div>
</template>

<script>
import Avatar, { colorOf } from "@/components/avatar";
import UnitType from "@/constant/unit-types";
import RoleType from "@/constant/role-types";

export default {
  components: { Avatar },
  props: {
    unit: Object
  },
  data() {
    return { RoleType };
  },
  computed: {
    avatar() {
      const unit = this.unit;
      if (unit.unitType === UnitType.TEAM) {
        return {
          src: "images/default_team.svg",
          size: "32px",
          border: false
        };
      }
      return {
        size: "36px",
        src: unit.avatar,
        text: unit.unitName,
        color: colorOf(unit.unitId),
        circle: true,
        border: false
      };
    }
  },
  filters: {
    roleLabel(roleType) {
      const types = [null, "只读", "可编辑", "可管理"];
      return types[roleType] || types[0];
    }
  },
  methods: {
    onConfirm(evt) {
      this.unit.roleType = evt.key;
      this.$emit("change", evt.key, this.unit);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.unit {
  display: flex;
  align-items: center;
  padding: 12px 0;
  .avatar-wrapper {
    flex-shrink: 0;
    margin-left: 16px;
  }
  .label {
    margin: 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-tag {
    flex-shrink: 0;
    border: none;
    border-radius: 2px;
  }
  .placeholder {
    flex: 1 1 0;
  }
  .rel-role {
    color: @text-color-secondary;
    cursor: pointer;
  }
  .rel-remove {
    color: @red-6;
    margin-left: 12px;
    cursor: pointer;
    &:hover {
      color: @red-4;
    }
  }
}
.ant-dropdown-menu-item {
  width: 100px;
}
</style>
