import Axios from "axios";
import { interceptor } from "./index";
import resolveURL from "@/utils/baseurl";

const axios = Axios.create({ baseURL: resolveURL("/api/team") });

interceptor.auth(axios);

export function creatTeam(data) {
  return axios.post("/create", data);
}

export function updateTeam(data) {
  return axios.post("/update", data);
}

export function deleteTeam(id) {
  return axios.delete(`/delete/${id}`);
}

export function treeOfTeam() {
  return axios.get("/tree");
}

export function unitOfTeam(params) {
  return axios.get("/getUnit", { params });
}

export function listMember(params) {
  return axios.get("/member/list", { params });
}

export function addMember(data) {
  return axios.post("/member/add", data);
}

export function updateMember(data) {
  return axios.post("/member/update", data);
}

export function deleteMember(data) {
  return axios.post("/member/delete", data);
}

export default axios;
