<template>
  <a-modal :closable="false" :width="width">
    <div class="title">{{ title }}</div>
    <a-input-search v-model="search" :placeholder="placeholder" />
    <!-- units -->
    <div class="units">
      <UnitTeam
        v-for="item in filteredTeams"
        :key="item.teamId"
        :team="item"
        @select="onSelect(item, $event)"
      />
      <UnitMember
        v-for="item in filteredMembers"
        :key="item.memberId"
        :member="item"
        @select="onSelect(item, $event)"
      />
    </div>
    <!-- footer buttons -->
    <a-button slot="footer" @click="$reject()">取消</a-button>
    <a-dropdown
      slot="footer"
      placement="topRight"
      :disabled="loading || !checkedUnits.length"
    >
      <a-menu slot="overlay" @click="onConfirm">
        <a-menu-item :key="RoleType.MANAGE">添加为「可管理」</a-menu-item>
        <a-menu-item :key="RoleType.EDIT">添加为「可编辑」</a-menu-item>
        <a-menu-item :key="RoleType.READ">添加为「只读」</a-menu-item>
      </a-menu>
      <a-button
        type="primary"
        :disabled="!checkedUnits.length"
        :loading="loading"
      >
        批量添加 <i class="ndl-icon-chevron-down"></i>
      </a-button>
    </a-dropdown>
    <span
      slot="footer"
      class="ndl-text-desc"
      style="float: left; margin-top: 6px"
    >
      已选择 {{ checkedUnits.length }} 项
    </span>
  </a-modal>
</template>

<script>
import RoleType from "@/constant/role-types";
import mixinPicker from "@/views/settings/components/unit-picker/src/Picker";

export default {
  mixins: [mixinPicker],
  props: {
    beforeConfirm: Function
  },
  data() {
    return { RoleType, loading: false };
  },
  methods: {
    async onConfirm(evt) {
      try {
        this.loading = true;
        const roleType = evt.key;
        const units = this.checkedUnits;
        if (this.beforeConfirm) {
          await this.beforeConfirm(units, roleType);
        }
        this.$resolve({ roleType, units });
      } catch (err) {
        this.$reject(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
