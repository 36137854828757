import UnitType from "@/constant/unit-types";
import Member from "./member";

export default class Team {
  teamId = "";
  teamName = "";
  parentId = "";
  unitId = this.teamId;
  unitType = UnitType.TEAM;
  isRoot = false;
  checked = false;
  // 子团队，暂时无子团队(2022-06)，且看后续需求
  children = [];
  /**
   * 此团队的所有成员（含子团队）
   * @type {Member[]}
   */
  members = [];
  constructor(opts = {}) {
    Object.assign(this, opts);
    this.setMembers(opts.members);
    this.unitId = this.teamId;
  }
  setMembers(members) {
    this.members = Array.isArray(members) ? members.map(Member.from) : [];
  }
  /**
   * @param {object | Team} opts
   * @returns {Team}
   */
  static from(opts) {
    return opts instanceof Team ? opts : new Team(opts);
  }
}
