<template>
  <div class="team" @click="onClick()">
    <Avatar size="36px" src="images/default_team.svg" :border="false" />
    <div class="meta">
      <div class="name">
        <span>{{ team.teamName }}</span>
        <a-tag v-if="team.isRoot" color="orange">此空间</a-tag>
      </div>
      <span class="info">
        {{ team.isRoot ? "共" : "" }} {{ team.members.length }} 人
      </span>
    </div>
    <a-checkbox v-model="team.checked" @input="$emit('select', $event)" />
  </div>
</template>

<script>
import Avatar from "@/components/avatar";

export default {
  components: { Avatar },
  props: {
    team: Object
  },
  methods: {
    onClick() {
      const value = !this.team.checked;
      this.team.checked = value;
      this.$emit("select", value);
    }
  }
};
</script>

<style lang="less" scoped>
.team {
  height: 60px;
  padding: 0 20px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  &:hover {
    background: #f5f5f5;
  }
  .avatar-wrapper {
    flex-shrink: 0;
  }
  .meta {
    flex: 1 1 0;
    overflow: hidden;
    margin-left: 14px;
  }
  .name {
    margin-bottom: 4px;
  }
  .name > span:first-child {
    margin-right: 8px;
  }
  .ant-tag {
    border: none;
    border-radius: 2px;
    cursor: inherit;
  }
  .info {
    color: rgba(0, 0, 0, 0.45);
    font-size: 13px;
  }
}
.ant-checkbox-wrapper {
  flex-shrink: 0;
  margin-left: 12px;
}
</style>
